import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import styles from "./Contact.module.scss";
import Container from "./Container";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Contact = () => {
  const notify = () => toast.success("Xabar jo'natildi");
  const notifyErr = () => toast.error("Xatolik");

  const { t } = useTranslation("translation", { keyPrefix: "contact" });
  const [data, setData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const [checkErrors, setCheckErrors] = useState(false);

  const handleChange = (value, name) => setData({ ...data, [name]: value });

  const onSubmit = async (e) => {
    e.preventDefault();
    setCheckErrors(false);
    if (!data.name || !data.phone || !data.email || !data.message)
      setCheckErrors(true);
    else {
      const res = await fetch(
        `https://api.telegram.org/bot5954933348:AAEQqQQSAXywGObu7Lt2rrMQa2ZolKyq44s/sendMessage?chat_id=1069371886&text=${data.name}, ${data.phone}, ${data.email}, ${data.message}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.status === 200) notify();
      else notifyErr();
    }
  };

  return (
    <section id="contact" className={classNames(styles.contact)}>
      <ToastContainer />
      <Container>
        <div className={classNames(styles.wrapper)}>
          <div className={classNames(styles.left)}>
            <h2 className="content__heading">{t("title")}</h2>
            <ul className={classNames(styles.list)}>
              <li className={classNames(styles.item)}>
                <span className={styles.label}>{t("label-address")}</span>
                <span className={styles.value}>
                  {t("address-1")}
                  <br />
                  {t("address-2")}
                </span>
              </li>
              <li className={classNames(styles.item)}>
                <span className={styles.label}>{t("label-phone")}</span>
                <a href={"tel:+998975750184"} className={styles.value}>
                  +998 97 575 01 84
                </a>
              </li>
              <li className={classNames(styles.item)}>
                <span className={styles.label}>{t("label-email")}</span>
                <a
                  href="mailto:info@azzavajallagmail.uz"
                  className={styles.value}
                >
                  {t("email")}
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.right}>
            <h3 className={classNames(styles.form__title)}>
              {t("form-title")}
            </h3>
            <form
              action=""
              className={classNames(styles.form)}
              onSubmit={onSubmit}
            >
              {checkErrors && !data.name && (
                <p style={{ color: "red", marginBottom: "5px" }}>*Fill</p>
              )}
              <input
                value={data?.name || ""}
                onChange={(e) => handleChange(e.target.value, "name")}
                type="text"
                className={classNames("input", styles.input)}
                placeholder={t("placeholder-name")}
              />

              {checkErrors && !data.phone && (
                <p style={{ color: "red", marginBottom: "5px" }}>*Fill</p>
              )}

              <input
                value={data?.phone || ""}
                onChange={(e) => handleChange(e.target.value, "phone")}
                type="tel"
                className={classNames("input", styles.input)}
                placeholder={t("placeholder-phone")}
              />

              {checkErrors && !data.email && (
                <p style={{ color: "red", marginBottom: "5px" }}>*Fill</p>
              )}
              <input
                value={data?.email || ""}
                onChange={(e) => handleChange(e.target.value, "email")}
                type="email"
                className={classNames("input", styles.input)}
                placeholder={t("placeholder-email")}
              />

              {checkErrors && !data.message && (
                <p style={{ color: "red", marginBottom: "5px" }}>*Fill</p>
              )}
              <textarea
                value={data?.message || ""}
                onChange={(e) => handleChange(e.target.value, "message")}
                className={classNames("textarea", styles.textarea)}
                placeholder={t("placeholder-message")}
              />

              <Button type="submit" contained>
                {t("send")}
              </Button>
            </form>
          </div>
        </div>
      </Container>
    </section>
  );
};
